export default function Cdp() {
  const styles = {
    aspectRatio: "801 / 1036",
    width: "50.0625rem",
    background: "linear-gradient(to right bottom, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };

  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div style={styles} />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              MSME-CDP
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              MSME-CDP thil tum
            </h1>
            <ul role="list" className="mt-5 max-w-xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <span>
                  Sumdawngte (Micro leh Small Enterprises) hmasawnna leh dinhmun
                  changtlung zawk an neihna tur kawnga harsatna siam thei thil
                  hrang hrang te sut kian.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  Common Facility Centres din (training leh testing neih nan,
                  bungraw dah nan, thil siamchhuah nan leh adt.)
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  MSE te Industry neihna hmun a Infrastructure thar siam emaw a
                  hlui tihchangtlun Leilung tichhe lo tur thil siam chhuah
                </span>
              </li>
            </ul>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-8">
                <blockquote className="text-sm font-semibold leading-8 tracking-tight text-gray-900">
                  <p className="font-bold mt-8 text-xl">
                    Dil dan kalphung (sawrkar puina telin)
                  </p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>Step 1 : DPR</span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Step 2 : Small Industries Development Bank of India
                        (SIDBI) or other commercial banks
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Step 3 : State Level Steering Committee (SLSC)
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>Step 4 : MSME-DI</span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>Step 5 : Office of DC, MSME</span>
                    </li>
                  </ul>

                  <p className="font-bold mt-4">
                    Dil dan kalphung (sawrkar puihna tel lovin)
                  </p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>Step 1 : DPR (with recommendation of SLSC)</span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>Step 2 : MSME-DI</span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>Step 3 : DC-MSME</span>
                    </li>
                  </ul>

                  <figure className="mt-10 border-l border-red-600 pl-9">
                    <blockquote className="font-semibold text-gray-900">
                      <p>
                        Hrechiang duh tan ahnuaia link tarlanah hian a en theih
                        ang
                        <a
                          href="https://cluster.dcmsme.gov.in/"
                          className="text-red-600 pl-2"
                        >
                          https://cluster.dcmsme.gov.in/
                        </a>
                      </p>
                    </blockquote>
                  </figure>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">Scheme bung hrang pahnih te</p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    Common Facility Centres: Hmuh theih leh khawih theih
                    hmanrua/bungrua siamna, Design siamna leh product enchhinna
                    hmunpui. Installment hi ṭhuang thum 50:40:10 in pek chhuah
                    ṭhin a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Infrastructure Development: Hmunhma thar din emaw a hlui
                    tihchangtlun. Installment hi ṭhuang thum 50:40:10 in pek
                    chhuah ṭhin a ni.
                  </span>
                </li>
              </ul>

              <p className="font-bold mt-8 text-xl">Scheme dil thei te</p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    MSE-CDP hi ahma lama kan sawi tawh SFURTI ang chiahin
                    Cluster scheme a ni a, mimalin an dil thei lo. Diltute hi
                    MSME intelkhawm registration (Section 8 Company) mumal nei
                    an ni tur a ni;
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Special Purpose Vehicles (SPV) : Cluster leh Implementing
                    Agency te’n thawkhoa dan ang taka pawl an din.
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="row mt-8 align-items-center">
            <div className="col-12 col-lg-7 col-xl-12 text-lg-start text-center">
              <table id="table1">
                <thead>
                  <tr>
                    <th colspan="2">
                      Scheme apply thei te
                      <span>
                        Entrepreneurs ni tawh leh ni mek. [Special Purpose
                        Vehicles (SPVs) ni thei dinhmun a ding.
                      </span>
                    </th>
                    <th rowspan="2">Total Project Cost</th>
                    <th colspan="3">Funding Pattern</th>
                  </tr>
                  <tr>
                    <th colspan="2">Components</th>
                    <th>Govt. of India</th>
                    <th>State Govt</th>
                    <th>SPV</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td rowspan="2">A</td>
                    <td>A.1 Common Facility Centre</td>
                    <td>Vbc. 5 ațanga 10</td>
                    <td>80%</td>
                    <td>15%</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>A.2 Common Facility Centre</td>
                    <td>Vbc.10 atanga 30</td>
                    <td>70%</td>
                    <td>15%</td>
                    <td>15%</td>
                  </tr>
                  <tr>
                    <td rowspan="2">B</td>
                    <td>B.1 Infrastructure development- A thar tan</td>
                    <td>Vbc. 5 ațanga 15</td>
                    <td>70%</td>
                    <td>30%</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>B.2 Infrastructure development- Awm sa siamthat nan</td>
                    <td>Vbc. 5 ațanga 10</td>
                    <td>60%</td>
                    <td>40%</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row align-items-center pt-4">
            <div className="col-12 col-lg-7 col-xl-12 text-lg-start text-center">
              <h1 className="fs-2 fw-normal mt-6">
                State Level Steering Committee (SLSC) Composition
              </h1>
              <table id="table1">
                <thead>
                  <tr>
                    <th scope="col">Sl. No.</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Member Secretary / Convener</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      ACS/Principal Secretary / Secretary (Industries/MSME)
                    </td>
                    <td>Chairman</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Commissioner / Director of Industries / MSME</td>
                    <td>Co-Convener</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Managing Director or Representative of Implementing Agency
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Representative of Finance Department</td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Director, MSME-Development Institute</td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      General Manager, Concerned District Industries Centre
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>
                      Special invitees (if any) like representative of SIDBI or
                      any financial institution or any official required for the
                      purpose
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>
                      A representative from Technical Institution/MSMETechnology
                      Centres of MOMSME
                    </td>
                    <td>Member</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
