export default function Cgstmse() {
  return (
    <div className="">
      <div className="bg-white px-6 py-10 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-red-600">
            Sawifiahna
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            CGTMSE chu eng nge ni?
          </h1>
          <p className="mt-6 text-xl leading-8">
            He scheme hi Credit Guarantee Fund Scheme for Micro and Small
            Enterprises (CGS-I) ti a hriat ṭhin a ni a [he mi hma hian Credit
            Guarantee Fund Scheme for Small Industries (CGFSI) ti a hriat a ni
            bawk]. Hemi hnu hian MSMED Act-2006 hnuaiah dan ang takin Trust
            hming hi siamṭhain Credit Guarantee Fund Trust for Micro and Small
            Enterprises vuah a ni a, a scheme chuan Credit Guarantee Fund Scheme
            for Micro and Small Enterprises tih a lo ni ta a ni.
          </p>
          <div className="mt-10 max-w-2xl">
            <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Scheme thil tum
            </h3>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-red-600"
                  aria-hidden="true"
                /> */}
                <span>
                  Sumdawnna a bulṭan tak tak (a bikin loan vawikhat lakna tur-
                  First generation entrepreneurs) te’n a mahni thawhrimna hmang
                  ngeia lei rem rap tur a Loan dahkham tlem te ( a ṭhenah
                  dahkham awm lo) chauh hmanga an lak theih nana puih a ni.
                </span>
              </li>
            </ul>

            <div className="mt-12 max-w-2xl">
              <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                Scheme huamchin
              </h3>
              <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Sumdawnna a bulṭan tak tak (a bikin loan vawikhat lakna tur-
                    First generation entrepreneurs) te’n a mahni thawhrimna
                    hmang ngeia lei rem rap tur a Loan dahkham tlem te ( a
                    ṭhenah dahkham awm lo) chauh hmanga an lak theih nana puih a
                    ni.
                  </span>
                </li>
              </ul>
            </div>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Annual Guarantee Fee chungchang
            </h2>
            <strong className="font-semibold text-gray-900">
              Kum tin Guarantee Fee hi pek a ngai a, he fee hian loan pe
              chhuaktute tan invenna a pe ve a ni. A rate erawh kan loan lak zat
              a zir a siam rem a ni, a hnuai ami ang hian
            </strong>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <strong className="font-semibold text-gray-900">
                Loan kalhmang
              </strong>
              <li className="flex gap-x-2">
                {/* <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-red-600"
                  aria-hidden="true"
                /> */}
                <span>
                  A thar(new) emaw lo awm tawh(existing) emaw Micro leh Small
                  Lending hmanga credit facility pek tawhte institution-ah hian
                  collateral security leh/ emaw third party guarantee engmah a
                  awm lo.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-red-600"
                  aria-hidden="true"
                /> */}
                <span>
                  He scheme hmang vek a Loan dil nawn tum tan Manufacturing leh
                  Service sector ah chuan a kalphung/kalhmang hlui ang chiaha
                  dil leh theih a ni. Hei hian trade sector a huam lo thung.
                </span>
              </li>
            </ul>
            <figure className="mt-10 border-l border-red-600 pl-9">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  Hrechiang duh tan ahnuaia link tarlanah hian a en theih ang
                  <a
                    href="https://clcss.dcmsme.gov.in/"
                    className="text-red-600 pl-2"
                  >
                    https://clcss.dcmsme.gov.in/
                  </a>
                </p>
              </blockquote>
            </figure>

            <div className="col-md-6 mt-5">
              <div className="row align-items-center">
                <table id="fees_table">
                  <thead>
                    <tr>
                      <th>Fee Rate:</th>
                      <th>Standard Rate (%)</th>
                      <th>Fee Rate After Discount (10%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Nuai 0-10</td>
                      <td>0.37</td>
                      <td>0.33</td>
                    </tr>
                    <tr>
                      <td>Nuai 10-50</td>
                      <td>0.55</td>
                      <td>0.50</td>
                    </tr>
                    <tr>
                      <td>Nuai 50-Vbc.1</td>
                      <td>0.60</td>
                      <td>0.54</td>
                    </tr>
                    <tr>
                      <td>Vbc 1-2</td>
                      <td>1.20</td>
                      <td>1.08</td>
                    </tr>
                    <tr>
                      <td>Vbc 2-5</td>
                      <td>1.35</td>
                      <td>1.22</td>
                    </tr>
                    <tr>
                      <td colspan="3" className="wide-box">
                        <ul>
                          <li>
                            <p>Additional concession hi 10% a ni</p>
                          </li>
                          <li>
                            <p>
                              A kum khatnaah chuan sum intiamkam zat (guaranteed
                              amount) atanga AGF hi chhut tur a ni a. A kum dang
                              chu a sum intiamkam bang aṭanga chhut zel tur a
                              ni.
                            </p>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className="row align-items-center">
                <table className="loan-table" id="fees_table">
                  <thead>
                    <tr>
                      <th rowspan="2">
                        Category (sumdawnna huang hrang hrangte huamin)
                      </th>
                      <th colspan="3">
                        Loan lak zat chin aṭanga Guarantee (tiamkam) hmuh theih
                        zat
                      </th>
                    </tr>
                    <tr className=" text-xs">
                      <th>Nuai 5 chin</th>
                      <th>Nuai 5 atanga nuai 50</th>
                      <th>Nuai 50 atanga vbc. 5</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Micro Enterprises</td>
                      <td>85%</td>
                      <td>75%</td>
                      <td>75%</td>
                    </tr>
                    <tr>
                      <td>
                        North-East huamchhung MSE (Sikkim, UT of Jammu & Kashmir
                        and UT of Ladakh a huam tel)
                      </td>
                      <td>80%</td>
                      <td>80%</td>
                      <td>75%</td>
                    </tr>
                    <tr>
                      <td>
                        Hmeichhia entrepreneurs/SC/ST entrepreneurs/Aspirational
                        District a awm te/ZED certified/Rualban lo(PwD)/MSE
                        promoted by Agniveers
                      </td>
                      <td>85%</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>Kawng dang hmang a loan la tu</td>
                      <td>75%</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
