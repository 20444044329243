export default function Cicss() {
  return (
    <div className="">
      <div className="bg-white px-6 py-10 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-red-600">
            Introducing
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            CLCS-TUS/SCLCSS chu eng nge?
          </h1>
          <p className="mt-6 text-xl leading-8">
            Ministry of Micro, Small and Medium Enterprise (MoMSME) hnuaiah hian
            MSE ho tan a Technology te, Plant & Machinery te tih changtlunna tur
            scheme ṭhenkhat duan a ni a, chung zinga pakhat chu Credit Linked
            Capital Subsidy Scheme (CLCSS) a n. He CLCSS hian peng hrang
            (component) neiin Credit Linked Capital Subsidy Technology
            Up-gradation Scheme (CLCS-TUS) leh North-East tan bik a duan Special
            Credit Linked Capital Subsidy Scheme (SCLCSS) hi an ni.
          </p>
          <div className="mt-10 max-w-2xl">
            <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Scheme nihphung leh thil tum
            </h3>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  <strong className="font-semibold text-gray-900">
                    Tunlai thiamna
                  </strong>
                  leh
                  <strong className="font-semibold text-gray-900">
                    theihna
                  </strong>
                  sang ber hmanga kalpui tur niin, khawl pawh a changkang thei
                  ang ber zel hman tum a ni.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  Sawrkar hriatpui loan kaltlanga lei khawl(plant & machinery)
                  te chauh he hamthatna hian a huam.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  CLCS-TUS hnuaiah incentive hi unit lo awm tawh (existing) emaw
                  a thar (new) tan avail theih a ni.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  Packaging lama hmasawnna tur bun belh bakah pollution siam
                  belh lo tur leh leilung hausakna renchem kawngah hma a la tel
                  dawn bawk a ni.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  Mimal khawl phuah chawp emaw siam chawp hman theih a ni lo a;
                  Tin, a hlui lei hman phal a ni lo.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  He scheme component hnuaia subsidy hmang tawhtu techuan
                  Central, State/UT Sawrkar atangin technology tihchangtlunna
                  atan leh chuti ang kaihhnawih chhan dang hmang pawhin subsidy
                  dang an dawng thei lo vang.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  CLCS-TUS hnuai a subsidy hmang hian Khawl/Technology hlui tawh
                  angai vek a thlak (replace) phal a niloa, hetiang chiah hian
                  Khawl hlui (secondhand machinery) tih changtlun tum pawh phal
                  a nilo a ni.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  Sector pakhat hnuaia machinery tling/pawm (eligible) tawh chu
                  sector dang tan pawh eligible ang a ngaih theih a ni a,
                  chutihrualin, a mamawh chu technical angle atanga a dik a nih
                  chuan chu sector bik tan chuan a eligibility tihkhawtlai a
                  nilo ang.
                </span>
              </li>
            </ul>
            <p className="mt-8">
              CLCS-TUS hnuaia incentive hi unit lo awm tawh (existing) emaw a
              thar (new) tan dil theih niin, Loan hi a tam berah vbc 1 lak theih
              a ni. CLCS-TUS ah hian 15% capital subsidy pek a ni bawk.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Loan leh subsidy chungchang
            </h2>
            <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
              <strong className="font-semibold text-gray-900">
                Loan lak theih zat hi a tam berah cheng vbc. 1 a ni.
              </strong>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  SCLCSS hnuaia Loan la ah hian atam ber a cheng nuai 25 la te
                  hnenah capital subsidy 25% pek an ni a, hei hi Hmar-Chhak mi
                  (NE) tan bik a ruahman a ni; CLCS-TUS ah hian 15% capital
                  subsidy pek a ni bawk.
                </span>
              </li>
              <li className="flex gap-x-3">
                {/* <CheckCircleIcon
                            className="mt-1 h-5 w-5 flex-none text-red-600"
                            aria-hidden="true"
                        /> */}
                <span>
                  CLCS-TUS ah hian 15% capital subsidy pek a ni bawk. (He scheme
                  hi retrospective date atanga kalpui a nih avangin subsidy hi a
                  hmaa tih dan angin eligible machinery-a Investment-ah chhut
                  tur a ni. Capital subsidy quantum chu eligible investment
                  aṭanga 15% (a tam ber Rs.15 lakh thleng)-ah restricted a ni
                  ang)
                </span>
              </li>
            </ul>
            <figure className="mt-10 border-l border-red-600 pl-9">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  Hrechiang duh tan ahnuaia link tarlanah hian a en theih ang
                  <a
                    href="https://clcss.dcmsme.gov.in/"
                    className="text-red-600 pl-2"
                  >
                    https://clcss.dcmsme.gov.in/
                  </a>
                </p>
              </blockquote>
            </figure>
          </div>
          <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Scheme dil dan kal hmang
            </h2>
            <p className="mt-6">
              Dilna hi online hmanga kalpui tur a ni a. Mahni a apply tur niloin
              Primary Lending Institutions (PLI) kaltlanga dil tur a ni. A
              kimchang chu guideline ah hmuh tur a awm e.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
