import React, { useEffect, useState } from "react";
import Authenticated from "../Layout/Authenticated";
import { Link, useNavigate } from "react-router-dom";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Hourglass, InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format, formatDate } from "date-fns";
import {
  Alert,
  Dialog,
  DialogTitle,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CheckBox, ErrorRounded } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const people = [
  {
    year: "2022-23",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

// console.log(
//   navigator.geolocation.getCurrentPosition((loc) => {
//   })
// );

const rows = [
  {
    id: 1,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "udyam_id",
    numeric: false,
    disablePadding: false,
    label: "Udyam ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  // {
  //   id: "address",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Address",
  //   sortable: false,
  // },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  // },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
  // {
  //   id: "protein",
  //   numeric: true,
  //   sortable: false,
  //   disablePadding: false,
  //   label: "",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export function Verify({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [units, setUnits] = useState([]);
  const [reject, setReject] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [isBusy, setIsbusy] = useState(true);
  const [tempData, setTempData] = useState({});
  const [entities, setEntities] = useState({
    data: [],
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);
  const [currentApprovingId, setCurrentApprovingId] = useState();
  const [view, setView] = useState();
  const [disclaimer, setDisclaimer] = useState();
  const [isAllBusy, setIsAllbusy] = useState(false);
  const [message, setMessage] = useState(false);
  const [udyamId, setUdyamId] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [noRemark, setNoRemark] = useState(false);

  const navigate = useNavigate();
  const verifyRow = async (e, row) => {
    e.preventDefault();
    setIsAllbusy(true);
    setView("verifies");
    await api
      .get(
        `/api/udyam/${row.unit?.udyam_id || row?.udyam_main_id || row.udyam_id}`
      )
      .then((res) => {
        row.udyam = res.data;
        const res_data = res.data;
        // res_data.units = res.data.units.map(e => {
        //   e.verified_unit=e.id === row.unit_id?true:false
        //   return e
        // })

        console.log(res_data);
      })
      .finally(() => {
        setIsAllbusy(false);
      });
    api.get(`/api/verify/${row.id}`).then((res) => {
      if (res.data.type === "verification_staff") {
        navigate(`/verify/${row.id}`);
      } else if (
        ["district_lead_resource", "m_and_e"].includes(res.data.type)
      ) {
        setView("approval");
        const dataTemp = JSON.parse(JSON.stringify(row));
        // dataTemp.udyam.investments = dataTemp.udyam.investments.map((e) => {
        //   e.is_itr_filled = e.is_itr_filled ? "Yes" : "No";
        //   return e;
        // });
        setCurrentApprovingId(row.id);
        setTempData(dataTemp.udyam);
        setOpen(true);
      }
    });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, data) => {
    event.stopPropagation();
    setView("details");
    if (data) {
      setIsAllbusy(true);
      api
        .get(
          `/api/udyam/${data.unit?.udyam_id || data?.udyam_main_id || data.udyam_id}`
        )
        .then((res) => {
          setTempData(res.data);
          const res_data = res.data;
          res_data.units = res.data.units.map((e) => {
            e.verified_unit = e.id === data.unit_id ? true : false;
            return e;
          });

          console.log(res_data);
        })
        .finally(() => {
          setIsAllbusy(false);
        });
    } else {
      const dataTemp = JSON.parse(JSON.stringify(data));

      // dataTemp.udyam.investments = dataTemp.udyam.investments.map((e) => {
      //   e.is_itr_filled = e.is_itr_filled ? "Yes" : "No";
      //   return e;
      // });
      dataTemp.unit_remarks = {};
      setTempData(dataTemp.udyam);
    }
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Saiha" },
    { id: 8, name: "Lawngtlai" },
    // More users...
  ];

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const handleUnitChange = async (e) => {
    const tempUnits = JSON.parse(JSON.stringify(units));
    if (!tempUnits.includes(e.id)) {
      tempUnits.push(e.id);
      setUnits(tempUnits);
    } else {
      tempUnits.splice(tempUnits.indexOf(e.id), 1);
      setUnits(tempUnits);
    }
    console.log(units);
  };

  const handleApproval = (is_reject) => {
    const form = {
      ...tempData,
      ...{ _method: "patch" },
    };
    if (is_reject === "rejection") {
      if (units.length < 1) {
        window.alert("Please select atleast one unit to reject");
        return 0;
      }
      form.is_reject = true;
      form.units_rejected = units;
    }

    api
      .post(`/api/verify/${currentApprovingId}`, form)
      .then((res) => {
        setOpen(false);
        getData(page);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsbusy(false));
  };
  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/verify`);

    page && url.searchParams.append("page", page);
    startDate && url.searchParams.append("startDate", startDate);
    startDate && url.searchParams.append("endDate", endDate);
    udyamId && url.searchParams.append("udyam_id", udyamId);
    ownerName && url.searchParams.append("owner_name", ownerName);
    email && url.searchParams.append("email", email);
    mobile && url.searchParams.append("mobile", mobile);
    noRemark && url.searchParams.append("no_remark", noRemark);
    selectedDistrict &&
      url.searchParams.append("district", selectedDistrict.toLowerCase());
    axios
      .get(url)
      .then((response) => {
        setEntities(response.data);
        setPage(response.data.current_page);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
        console.log(err);
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  const checkUdyam = (udyam) => {
    navigate(`/verify/${udyam.id}`);
  };

  useEffect(() => {
    getData(1);
  }, []);
  const handleRemoveField = async (field, index) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].filter((_, i) => i !== index),
    });
  };
  const handleAddField = (field) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].concat({}),
    });
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const submit = (e) => {
    e.preventDefault();
    // setIsAllbusy(true);
    // setOpen(false);
    const form = {
      ...tempData,
      ...{ _method: "patch" },
    };
    delete form.remarks_init;
    form.remarks = tempData.remarks_init;

    api
      .post(`/api/udyam/${tempData.id}`, form)
      .then((res) => {
        console.log(res);
        // console.log(res);
        getData(page);
        setMessage(
          "Your request to change the udyam has been submitted and will be processed accordingly..."
        );
        setOpen(false);
        setTimeout(() => {
          setMessage();
        }, 3000);
      })
      .catch((err) => {
        setOpen(true);
        console.log(err);
      })
      .finally(() => setIsAllbusy(false));
  };

  return (
    <div>
      {isAllBusy ? (
        <div className="h-screen  flex items-center justify-center">
          <InfinitySpin
            visible={true}
            width="200"
            color="#4fa94d"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <Authenticated>
          <div className="px-4 sm:px-6 lg:px-8 p-3 overflow-hidden">
            {message ? (
              <div className="bottom-3 mx-auto absolute bg-red-50 ">
                <Alert className="">{message}</Alert>
              </div>
            ) : (
              ""
            )}
            <Dialog
              maxWidth="lg"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div className=" sm:flex sm:flex-row items-center justify-center w-full">
                  {tempData?.id ? (
                    <div>
                      <div className="text-lg font-semibold">
                        Udyam Registration Number :
                      </div>
                      <div className="text-lg font-light ml-3">
                        {tempData.udyam_id}
                      </div>
                    </div>
                  ) : (
                    <div className="text-lg font-semibold">
                      Create New Udyam
                    </div>
                  )}
                </div>
              </DialogTitle>
              {view === "details" ? (
                <form onSubmit={submit} className="p-8 xl:p-15">
                  <div className="">
                    <div className="my-5">
                      {tempData?.id && (
                        <div>
                          <TextField
                            label="Remarks"
                            required
                            className="w-full"
                            size="small"
                            variant="outlined"
                            multiline
                            onChange={(e) =>
                              setTempData({
                                ...tempData,
                                remarks_init: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                      <div className="mt-5  grid gap-x-4">
                        <div className="mt-3 text-center sm:mt-5">
                          <div className=" sm:flex sm:flex-row items-center justify-center w-full">
                            <div className="text-lg font-light ">
                              {tempData?.submitEdit ? (
                                <div className="w-full flex gap-x-3 ">
                                  <TextField
                                    label="Udyam Registration Number"
                                    className="xl:w-96"
                                    size="small"
                                    required
                                    variant="outlined"
                                    value={tempData.udyam_id}
                                    onChange={(e) =>
                                      setTempData({
                                        ...tempData,
                                        udyam_id: e.target.value,
                                      })
                                    }
                                  />
                                  {tempData?.id && (
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        setTempData({
                                          ...tempData,
                                          submitEdit: !tempData.submitEdit,
                                        });
                                      }}
                                      color="error"
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div className="w-full ">
                                  <Button
                                    className=""
                                    onClick={() => {
                                      setTempData({
                                        ...tempData,
                                        submitEdit: !tempData.submitEdit,
                                      });
                                    }}
                                    variant="contained"
                                  >
                                    Edit Data
                                  </Button>
                                  <div
                                    className="mt-5 w-full"
                                    style={{ width: "70vw" }}
                                  >
                                    Remarks
                                    {tempData?.remarks?.map((remark, index) => {
                                      return (
                                        <div
                                          className="border w-full px-5 py-3 rounded-md"
                                          key={remark.id}
                                        >
                                          <div
                                            className="col-span-5  text-lg text-left"
                                            style={{
                                              fontSize: "15px",
                                              width: "100% important",
                                            }}
                                          >
                                            {remark.remarks}
                                          </div>
                                          <div className="grid grid-cols-2">
                                            <div className="text-xs text-left">
                                              {remark.user?.name}
                                            </div>
                                            <div className="text-xs text-right">
                                              {formatDate(
                                                new Date(remark.created_at),
                                                "dd-MM-yy hh:mm a"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {tempData?.submitEdit ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                              <div className="w-full">
                                <TextField
                                  label="Type of Enterprise"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      type_of_enterprise: e.target.value,
                                    })
                                  }
                                  value={tempData.type_of_enterprise}
                                />
                              </div>
                              <div className="">
                                <TextField
                                  label="Type of Organisation"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      type_of_organisation: e.target.value,
                                    })
                                  }
                                  value={tempData.type_of_organisation}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Owner Name"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      owner_name: e.target.value,
                                    })
                                  }
                                  value={tempData.owner_name}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Do you have GSTin"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      gstin: e.target.value,
                                    })
                                  }
                                  value={tempData.gstin}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Email"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      email: e.target.value,
                                    })
                                  }
                                  value={tempData.email}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Gender"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      gender: e.target.value,
                                    })
                                  }
                                  value={tempData.gender}
                                />
                              </div>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    tempData.date_of_incorporation
                                      ? new dayjs(
                                          tempData.date_of_incorporation
                                        )
                                      : null
                                  }
                                  format="DD-MM-YYYY"
                                  slotProps={{ textField: { size: "small" } }}
                                  label="Date of Incorporation"
                                  onChange={(newValue) =>
                                    setTempData({
                                      ...tempData,
                                      date_of_incorporation: format(
                                        new Date(newValue.$d),
                                        "yyyy-MM-dd"
                                      ),
                                    })
                                  }
                                  className=""
                                />
                              </LocalizationProvider>
                              <div className="w-full">
                                <TextField
                                  label="Major Activity"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      major_activity: e.target.value,
                                    })
                                  }
                                  value={tempData.major_activity}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Name of Enterprise"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      name_of_enterprise: e.target.value,
                                    })
                                  }
                                  value={tempData.name_of_enterprise}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="PAN"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      pan: e.target.value,
                                    })
                                  }
                                  value={tempData.pan}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Mobile"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      mobile: e.target.value,
                                    })
                                  }
                                  value={tempData.mobile}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Social Category"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      social_category: e.target.value,
                                    })
                                  }
                                  value={tempData.social_category}
                                />
                              </div>
                              <div className="w-full">
                                <TextField
                                  label="Specially Disabled/DIVYANG"
                                  className="w-full"
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setTempData({
                                      ...tempData,
                                      specially_disabled: e.target.value,
                                    })
                                  }
                                  value={tempData.specially_disabled}
                                />
                              </div>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    tempData.date_of_incorporation
                                      ? new dayjs(tempData.date_of_commencement)
                                      : null
                                  }
                                  format={
                                    tempData?.date_of_commencement
                                      ? "DD-MM-YYYY"
                                      : ""
                                  }
                                  slotProps={{ textField: { size: "small" } }}
                                  label="Date of Commencement of Production/Business"
                                  onChange={(newValue) =>
                                    setTempData({
                                      ...tempData,
                                      date_of_commencement: format(
                                        new Date(newValue.$d),
                                        "yyyy-MM-dd"
                                      ),
                                    })
                                  }
                                  className=""
                                />
                              </LocalizationProvider>
                            </div>
                          ) : (
                            ""
                          )}
                          {tempData.submitEdit ? (
                            <div className="mt-2">
                              <div className=" my-10">
                                <div className="space-y-12">
                                  <div className="border-b border-gray-900/10 pb-12"></div>
                                  {tempData.bank_detail && (
                                    <div className="border-b border-gray-900/10 pb-12">
                                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Bank Details
                                      </h2>
                                      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-10">
                                        <div className="w-full">
                                          <TextField
                                            label="Name"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) =>
                                              setTempData({
                                                ...tempData,
                                                bank_detail: {
                                                  ...tempData.bank_detail,
                                                  name: e.target.value,
                                                },
                                              })
                                            }
                                            value={tempData.bank_detail?.name}
                                          />
                                        </div>
                                        <div className="">
                                          <div className="w-full">
                                            <TextField
                                              label="IFSC"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) =>
                                                setTempData({
                                                  ...tempData,
                                                  bank_detail: {
                                                    ...tempData.bank_detail,
                                                    ifsc: e.target.value,
                                                  },
                                                })
                                              }
                                              value={tempData.bank_detail?.ifsc}
                                            />
                                          </div>
                                        </div>
                                        <div className="">
                                          <div className="w-full">
                                            <TextField
                                              label="Account No"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) =>
                                                setTempData({
                                                  ...tempData,
                                                  bank_detail: {
                                                    ...tempData.bank_detail,
                                                    account_no: e.target.value,
                                                  },
                                                })
                                              }
                                              value={
                                                tempData.bank_detail?.account_no
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {tempData.employment_detail && (
                                    <div className="border-b border-gray-900/10 pb-12">
                                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Employment Details
                                      </h2>
                                      <div className="grid grid-cols-1 md:grid-cols-4 mt-10 gap-3">
                                        <div className="w-full">
                                          <TextField
                                            label="Male"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) =>
                                              setTempData({
                                                ...tempData,
                                                employment_detail: {
                                                  ...tempData.employment_detail,
                                                  male: e.target.value,
                                                },
                                              })
                                            }
                                            value={
                                              tempData.employment_detail?.male
                                            }
                                          />
                                        </div>
                                        <div className="w-full">
                                          <TextField
                                            label="Female"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) =>
                                              setTempData({
                                                ...tempData,
                                                employment_detail: {
                                                  ...tempData.employment_detail,
                                                  female: e.target.value,
                                                },
                                              })
                                            }
                                            value={
                                              tempData.employment_detail?.female
                                            }
                                          />
                                        </div>
                                        <div className="w-full">
                                          <TextField
                                            label="Others"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) =>
                                              setTempData({
                                                ...tempData,
                                                employment_detail: {
                                                  ...tempData.employment_detail,
                                                  other: e.target.value,
                                                },
                                              })
                                            }
                                            value={
                                              tempData.employment_detail?.other
                                            }
                                          />
                                        </div>
                                        <div className="w-full">
                                          <TextField
                                            label="Total"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            disabled
                                            value={
                                              Number.parseInt(
                                                tempData.employment_detail?.male
                                              ) +
                                              Number.parseInt(
                                                tempData.employment_detail
                                                  ?.female
                                              ) +
                                              Number.parseInt(
                                                tempData.employment_detail
                                                  ?.other
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                      Investment in Plant and Machinery OR
                                      Equipment (in Rs.)
                                      <Button
                                        onClick={() =>
                                          handleAddField("investments")
                                        }
                                      >
                                        <PlusCircleIcon className="h-7 w-7" />
                                      </Button>
                                    </h2>
                                    {tempData?.investments?.map(
                                      (investment, index) => (
                                        <div
                                          className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-10 pl-10 relative"
                                          key={index}
                                        >
                                          <div className="absolute top-0 font-bold text-xl">
                                            {index + 1}
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              label="Financial Year"
                                              className="w-full"
                                              size="small"
                                              required
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].financial_year =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.financial_year}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              label="Enterprise Type"
                                              className="w-full"
                                              required
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].enterprise_type =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.enterprise_type}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Written Down Value"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[index].wdv =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.wdv}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label=" Exclusion of cost"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].exclusion_of_cost =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={
                                                investment.exclusion_of_cost
                                              }
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Net Investment in Plant and Machinery/Equipment"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].net_investment =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.net_investment}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Total Turnover"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].total_turnover =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.total_turnover}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Export Turnover"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].export_turnover =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.export_turnover}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Net Turnover"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[
                                                  index
                                                ].net_turnover = e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.net_turnover}
                                            />
                                          </div>
                                          <FormControlLabel
                                            label="Is ITR Filled?"
                                            control={
                                              <Checkbox
                                                checked={
                                                  investment.is_itr_filled === 1
                                                }
                                                onChange={() => {
                                                  const newInvestments = [
                                                    ...tempData.investments,
                                                  ];
                                                  newInvestments[
                                                    index
                                                  ].is_itr_filled =
                                                    investment.is_itr_filled ===
                                                    1
                                                      ? 0
                                                      : 1;
                                                  setTempData({
                                                    ...tempData,
                                                    investments: newInvestments,
                                                  });
                                                }}
                                              />
                                            }
                                          />
                                          <div className="w-full">
                                            <TextField
                                              label="ITR Type"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newInvestments = [
                                                  ...tempData.investments,
                                                ];
                                                newInvestments[index].itr_type =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  investments: newInvestments,
                                                });
                                              }}
                                              value={investment.itr_type}
                                            />
                                          </div>
                                          <div className="w-full md:col-span-2">
                                            <Button
                                              onClick={() =>
                                                handleRemoveField(
                                                  "investments",
                                                  index
                                                )
                                              }
                                              variant="contained"
                                              color="error"
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                      Unit(s) Details
                                      <Button
                                        onClick={() => handleAddField("units")}
                                      >
                                        <PlusCircleIcon className="h-7 w-7" />
                                      </Button>
                                    </h2>
                                    {tempData?.units?.map((unit, index) => {
                                      return (
                                        <div
                                          key={unit.id}
                                          className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 relative pl-10 rounded-md"
                                          style={{
                                            borderWidth: "1px",
                                            padding: "40px",
                                            marginTop:
                                              index > 0 ? "40px" : "20px",
                                          }}
                                        >
                                          {unit.verified_unit && (
                                            <div className="bg-white px-2 text-red-500 absolute font-bold text-xl -top-3.5 right-1/2 translate-x-1/2">
                                              Currently Verified
                                            </div>
                                          )}
                                          <div
                                            className="absolute font-bold text-xl left-3"
                                            style={{ marginTop: "40px" }}
                                          >
                                            {index + 1}
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Unit Name"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].name =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.name}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Flat"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].flat =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.flat}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Building"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].building =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.building}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Village/Town"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].village =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.village}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Block"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].block =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.block}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Road"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].road =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.road}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="City"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].city =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.city}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="Pin"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].pin =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.pin}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="State"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].state =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.state}
                                            />
                                          </div>
                                          <div className="w-full">
                                            <TextField
                                              required
                                              label="District"
                                              className="w-full"
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                const newUnits = [
                                                  ...tempData.units,
                                                ];
                                                newUnits[index].district =
                                                  e.target.value;
                                                setTempData({
                                                  ...tempData,
                                                  units: newUnits,
                                                });
                                              }}
                                              value={unit.district}
                                            />
                                          </div>
                                          <div className="w-full md:col-span-2">
                                            <Button
                                              onClick={() =>
                                                handleRemoveField(
                                                  "units",
                                                  index
                                                )
                                              }
                                              variant="contained"
                                              color="error"
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="border-b border-gray-900/10 pb-12">
                                  <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                                    Official address of Enterprise
                                  </h2>
                                  <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                                    <div className="w-full">
                                      <TextField
                                        label="Flat/Door/Block No"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              flat: e.target.value,
                                            },
                                          });
                                        }}
                                        value={tempData?.official_address?.flat}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Village/Town"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              village: e.target.value,
                                            },
                                          });
                                        }}
                                        value={
                                          tempData?.official_address?.village
                                        }
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Road/Street/Lane"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              road: e.target.value,
                                            },
                                          });
                                        }}
                                        value={tempData?.official_address?.road}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="State"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              state: e.target.value,
                                            },
                                          });
                                        }}
                                        value={
                                          tempData?.official_address?.state
                                        }
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Mobile"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              mobile: e.target.value,
                                            },
                                          });
                                        }}
                                        value={
                                          tempData?.official_address?.mobile
                                        }
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Name of Premises/Building"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              name: e.target.value,
                                            },
                                          });
                                        }}
                                        value={tempData?.official_address?.name}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Block"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              block: e.target.value,
                                            },
                                          });
                                        }}
                                        value={
                                          tempData?.official_address?.block
                                        }
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="City"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              city: e.target.value,
                                            },
                                          });
                                        }}
                                        value={tempData?.official_address?.city}
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="District"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              district: e.target.value,
                                            },
                                          });
                                        }}
                                        value={
                                          tempData?.official_address?.district
                                        }
                                      />
                                    </div>
                                    <div className="w-full">
                                      <TextField
                                        label="Email"
                                        className="w-full"
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setTempData({
                                            ...tempData,
                                            official_address: {
                                              ...tempData.official_address,
                                              email: e.target.value,
                                            },
                                          });
                                        }}
                                        value={
                                          tempData?.official_address?.email
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="border-b border-gray-900/10 pb-12">
                                  <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                                    National Industry Classification Code(S)
                                    <Button
                                      onClick={() => handleAddField("nics")}
                                    >
                                      <PlusCircleIcon className="h-7 w-7" />
                                    </Button>
                                  </h2>
                                  {tempData?.nics?.map((nic, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 pl-10 relative"
                                      >
                                        <div className="absolute text-xl font-bold">
                                          {index + 1}
                                        </div>
                                        <div className="w-full">
                                          <TextField
                                            label="Nic 5 Digit"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => {
                                              const newNics = [
                                                ...tempData.nics,
                                              ];
                                              newNics[index].nic_id =
                                                e.target.value;
                                              setTempData({
                                                ...tempData,
                                                nics: newNics,
                                              });
                                            }}
                                            value={nic.nic_id}
                                          />
                                        </div>
                                        <div className="w-full">
                                          <TextField
                                            label="Main Activity"
                                            className="w-full"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => {
                                              const newNics = [
                                                ...tempData.nics,
                                              ];
                                              newNics[index].activity =
                                                e.target.value;
                                              setTempData({
                                                ...tempData,
                                                nics: newNics,
                                              });
                                            }}
                                            value={nic.activity}
                                          />
                                        </div>
                                        <div className="w-full md:col-span-2">
                                          <Button
                                            onClick={() =>
                                              handleRemoveField("nics", index)
                                            }
                                            variant="contained"
                                            color="error"
                                          >
                                            Remove
                                          </Button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="w-full mt-10 grid grid-cols-1">
                                  <div>
                                    <FormControlLabel
                                      className="w-full"
                                      label=" Are you interested to get registered on
                                    Government e-Market (GeM) Portal"
                                      control={
                                        <Checkbox
                                          checked={tempData.gem_portal === 0}
                                          onChange={(e) => {
                                            setTempData({
                                              ...tempData,
                                              gem_portal: e.target.checked
                                                ? 1
                                                : 0,
                                            });
                                          }}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      className="w-full"
                                      label="Are you interested to get registered on TReDS
                                  Portals(one or more) "
                                      control={
                                        <Checkbox
                                          checked={tempData.treds_portal === 1}
                                          onChange={(e) => {
                                            setTempData({
                                              ...tempData,
                                              treds_portal: e.target.checked
                                                ? 1
                                                : 0,
                                            });
                                          }}
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      className="w-full"
                                      label="Are you interested to get registered on TReDS
                                  Portals(one or more) "
                                      control={
                                        <Checkbox
                                          checked={tempData.ncs_portal === 1}
                                          onChange={(e) => {
                                            setTempData({
                                              ...tempData,
                                              ncs_portal: e.target.checked
                                                ? 1
                                                : 0,
                                            });
                                          }}
                                        />
                                      }
                                    />
                                  </div>
                                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-5">
                                    <TextField
                                      label="DIC"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setTempData({
                                          ...tempData,
                                          dic: e.target.value,
                                        })
                                      }
                                      value={tempData.dic}
                                    />
                                    <TextField
                                      label="MSME DI"
                                      className="w-full"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) =>
                                        setTempData({
                                          ...tempData,
                                          msme_di: e.target.value,
                                        })
                                      }
                                      value={tempData.msme_di}
                                    />
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        value={
                                          tempData.date_of_registration
                                            ? new dayjs(
                                                tempData.date_of_registration
                                              )
                                            : null
                                        }
                                        format="DD-MM-YYYY"
                                        slotProps={{
                                          textField: { size: "small" },
                                        }}
                                        label="Udyam Registration Date"
                                        onChange={(newValue) =>
                                          setTempData({
                                            ...tempData,
                                            date_of_registration: format(
                                              new Date(newValue.$d),
                                              "yyyy-MM-dd"
                                            ),
                                          })
                                        }
                                        className=""
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                                <div className="border-b border-gray-900/10 pb-12">
                                  <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                                    Helpline Remarks :
                                  </h2>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="w-full mt-5 rounded-md border-gray-300"
                            style={{ borderWidth: "1px" }}
                          >
                            {tempData.checks &&
                              tempData?.checks?.map((check, index) => (
                                <div className="grid md:grid-cols-3">
                                  <div
                                    className=" text-xs p-2 relative"
                                    style={{
                                      borderRightWidth: "1px",
                                      borderBottomWidth:
                                        index !== tempData.checks.length - 1
                                          ? "1px"
                                          : "0px",
                                    }}
                                  >
                                    {check?.user?.name}
                                  </div>
                                  <div
                                    className=" text-xs p-2"
                                    style={{
                                      borderRightWidth: "1px",
                                      borderBottomWidth:
                                        index !== tempData.checks.length - 1
                                          ? "1px"
                                          : "0px",
                                    }}
                                  >
                                    {formatDate(
                                      new Date(check?.checked_on),
                                      "dd-MM-yyyy"
                                    )}
                                  </div>
                                  <div
                                    className=" text-xs p-2"
                                    style={{
                                      borderRightWidth: "1px",
                                      borderBottomWidth:
                                        index !== tempData.checks.length - 1
                                          ? "1px"
                                          : "0px",
                                    }}
                                  >
                                    {check?.remarks}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 mt-10">
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                      <Button
                        className="mt-3 md:mt-0"
                        color="error"
                        onClick={() => setOpen(false)}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="px-10 pb-5">
                  {
                    <div>
                      <Checkbox
                        checked={disclaimer === true}
                        label=""
                        onChange={() => {
                          setDisclaimer(!disclaimer);
                        }}
                      />
                      <span>
                        I have approved this udyam and all the details are true.
                      </span>
                    </div>
                  }
                  {tempData.units && !disclaimer && (
                    <div
                      className="border-gray-300 p-2 mt-3 rounded-md relative"
                      style={{ borderWidth: "1px" }}
                    >
                      <div className="absolute -top-2.5 bg-white px-2 left-4">
                        Unit
                        {JSON.stringify(tempData.unit_remarks)}
                      </div>
                      {tempData?.units?.map((e, index) => {
                        return (
                          <div key={e.id} className=" grid md:grid-cols-2">
                            <div className="flex place-items-center">
                              <Checkbox
                                key={e.id}
                                size="small"
                                checked={units.includes(e.id)}
                                onChange={(event) => handleUnitChange(e, event)}
                              />
                              <span>
                                {e.id}

                                {e.remarks}
                              </span>
                            </div>
                            <div className="w-full my-5 ">
                              <TextField
                                size="small"
                                multilinen
                                dense
                                className="w-full"
                                label="Remarks"
                                onChange={(e) => {
                                  const temporary = tempData;
                                  temporary.units[index].remarks =
                                    e.target.value;
                                  setTempData(temporary);
                                }}
                                rows={5}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="w-full my-5 ">
                    <TextField
                      multilinen
                      dense
                      className="w-full"
                      label="Remarks"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          remarks_init: e.target.value,
                        })
                      }
                      rows={5}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <Button
                      onClick={() => handleApproval()}
                      disabled={!disclaimer}
                      variant="contained"
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={() => handleApproval("rejection")}
                      disabled={disclaimer}
                      color="error"
                      variant="contained"
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              )}
            </Dialog>
            <div className="">
              <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
                <TextField
                  label="Udyam ID"
                  className="w-full"
                  size="small"
                  variant="outlined"
                  onChange={(e) => setUdyamId(e.target.value)}
                />
                <TextField
                  label="Owner Name"
                  className="w-full"
                  size="small"
                  variant="outlined"
                  onChange={(e) => setOwnerName(e.target.value)}
                />

                <TextField
                  label="Email"
                  className="w-full"
                  size="small"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  label="Mobile"
                  className="w-full"
                  size="small"
                  variant="outlined"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
            </div>
            <div className="md:mt-10 mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* <div className=" w-full">
                <TextField
                  className="w-full"
                  select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDistrict}
                  label="District"
                  onChange={(e) => setselectedDistrict(e.target.value)}
                >
                  {district.map((dist) => (
                    <MenuItem key={dist.id} value={dist.name}>
                      {dist.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { size: "small" } }}
                    label="From Date"
                    onChange={(newValue) =>
                      setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                    }
                    className="w-full"
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { size: "small" } }}
                    label="To Date"
                    onChange={(newValue) =>
                      setEndDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                    }
                    className="w-full"
                  />
                </LocalizationProvider>
              </div>
              <FormControlLabel
                className="w-full"
                label="No remarks"
                control={
                  <Checkbox
                    checked={noRemark}
                    onChange={(e) => {
                      setNoRemark(e.target.checked);
                    }}
                  />
                }
              />
              <div className=" grid grid-cols-2  gap-x-3 mx-3">
                <Button
                  type="button"
                  onClick={() => getData(1)}
                  variant="contained"
                  size="small"
                >
                  Submit
                </Button>
                <Button
                  onClick={() => window.location.reload()}
                  variant="contained"
                  color="error"
                  size="small"
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                {isBusy ? (
                  <div
                    className="flex justify-center"
                    style={{ zIndex: "3000" }}
                  >
                    <InfinitySpin
                      visible={true}
                      width="200"
                      color="#4fa94d"
                      ariaLabel="infinity-spin-loading"
                    />
                  </div>
                ) : (
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                    <div className="max-w-full overflow-hidden">
                      <Paper>
                        <TableContainer>
                          <Table
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {!isMobile && (
                              <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                              />
                            )}
                            <TableBody>
                              {entities?.data.map((row, index) => {
                                const isItemSelected = selected.includes(
                                  row.id
                                );
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                  <TableRow
                                    sx={{
                                      cursor: "pointer",
                                      display: isMobile ? "grid" : "table-row",
                                    }}
                                    style={{
                                      backgroundColor: isMobile ? "" : "white",
                                      borderWidth: isMobile ? "1px" : "0px",
                                      borderRadius: isMobile ? "3px" : "0px",
                                      marginTop: isMobile ? "3px" : "0px",
                                      padding: isMobile ? "3px" : "0px",
                                      borderColor: isMobile ? "grey" : "white",
                                    }}
                                    hover
                                    onClick={(event) =>
                                      !row.delete_approvals?.length &&
                                      handleClick(event, row)
                                    }
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    className={
                                      row?.delete_approvals?.length &&
                                      "bg-red-100 "
                                    }
                                  >
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="left"
                                    >
                                      {row?.entity?.udyam_id ||
                                        row?.unit?.udyam?.udyam_id ||
                                        row?.udyam?.udyam_id ||
                                        row.udyam_id}
                                    </TableCell>
                                    <TableCell align="left">
                                      <div>
                                        {row.name ||
                                          row?.udyam?.owner_name ||
                                          row?.unit?.udyam?.owner_name ||
                                          row.owner_name}
                                      </div>
                                      {row?.unit?.name || row.unit_name}
                                    </TableCell>
                                    {/* <TableCell align="left">
                                  {row?.district}
                                </TableCell> */}
                                    <TableCell align="left">
                                      <a
                                        onClick={(e) => e.stopPropagation()}
                                        rel="noreferrer noopener"
                                        href={`tel:${
                                          row?.entity?.mobile ||
                                          row?.udyam?.mobile ||
                                          row?.unit?.udyam?.mobile ||
                                          row?.mobile
                                        }`}
                                        className="cursor-pointer hover:text-indigo-600"
                                      >
                                        {row?.entity?.mobile ||
                                          row?.udyam?.mobile ||
                                          row?.unit?.udyam?.mobile ||
                                          row?.mobile}
                                      </a>
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.checks?.length ? (
                                        <div className="">
                                          {row.checks.map((check) => {
                                            return (
                                              <div
                                                className="flex justify-right w-full"
                                                key={check.id}
                                              >
                                                {check?.user?.name}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        <button
                                          disabled={
                                            row.delete_approvals?.length
                                          }
                                          className="text-white bg-blue-700 hover:bg-blue-500 w-28 h-8 rounded-md"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            verifyRow(e, row);
                                          }}
                                        >
                                          Verify Now
                                          <span className="sr-only">
                                            {row.name}
                                          </span>
                                        </button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {entities?.data.length - entities?.per_page >
                                0 && (
                                <TableRow
                                  style={{
                                    height:
                                      (dense ? 33 : 53) *
                                      (entities?.data.length -
                                        entities?.per_page),
                                  }}
                                >
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="relative py-3">
                          <div className="mt-2 w-full md:absolute md:left-1/2 md:-translate-x-1/2 flex justify-center z-30">
                            <Pagination
                              count={Math.ceil(
                                entities.total / entities.per_page
                              )}
                              page={entities.current_page}
                              onChange={(e, number) => getData(number)}
                            />
                          </div>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={entities?.total}
                            rowsPerPage={entities?.per_page}
                            page={entities?.current_page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Authenticated>
      )}
    </div>
  );
}
