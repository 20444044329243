export default function Pmegp() {
  const styles = {
    aspectRatio: "801/1036",
    width: "50.0625rem",
    background: "linear-gradient(to top right, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };
  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={styles}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              PMEGP
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              PMEGP scheme chu eng nge ni?
            </h1>
            <ul role="list" className="mt-5 max-w-xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <span>
                  Mahni intodelh tum a hna emaw sumdawnna kawng thar nei duhte
                  ṭanpui a, hlawhtling tur a an dinhmun chawikansak thei tur a
                  duan a ni.
                </span>
              </li>
            </ul>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-8">
                <blockquote className="text-lg font-normal leading-8 tracking-tight text-gray-900">
                  <p className="font-extrabold mt-3">
                    PMEGP hnuai a Trade thlan tur awm te
                  </p>

                  <ul
                    role="list"
                    className="mt-4 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        Sevice & Taxtile hnuaiah Industry thlan tur 76 a awm
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        Rural Engineering & Bio-technological Industry hnuaiah
                        hnuaiah Industry thlan tur 65 a awm
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Food Processing Industry hnuaiahIndustry thlan tur 33 a
                        awm
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        Mineral Based Industry hnuaiah Industry thlan tur 33 a
                        awm
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        Polymer & Chemical-based Industry hnuaiah Industry thlan
                        tur 33 a awm
                      </span>
                    </li>

                    <li className="flex gap-x-3">
                      <span>
                        Handmade Paper & Fibre Industry hnuaiah Industry thlan
                        tur 14 a awm
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Forest and Agro based Industry hnuaiah Industry thlan
                        tur 12 a awm
                      </span>
                    </li>
                  </ul>

                  <div className="col-md-6">
                    <div className="row align-items-center mt-7">
                      <h4 className="fw-normal">
                        Entrepreneurship Development Programme (EDP) chungchang
                      </h4>
                      <div className="row align-items-center">
                        <table id="table1">
                          <tbody>
                            <tr>
                              <th>EDP Training hun chhung</th>
                              <th>Cost of Project</th>
                            </tr>
                            <tr>
                              <td>A rei lo ber ah ni 5</td>
                              <td>Nuai 5 chin</td>
                            </tr>
                            <tr>
                              <td>A rei lo ber ah ni 10</td>
                              <td>Nuai 10 chung lam</td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="small">
                          <span className="font-italic">
                            *Nuai 2 chin dil tan erawh training neih angai lo
                            thung
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8">
                    <h1 className="fs-2 fw-normal mt-0">
                      He scheme hnuaia loan lo la tawh (existing) lak nawn leh
                      tum te hriat tur
                    </h1>
                    <div className="row align-items-center">
                      <table id="table1">
                        <tbody>
                          <tr>
                            <td>Categories of beneficiaries under PMEGP</td>
                            <td>
                              Beneficiary's contribution (of project cost)
                            </td>
                            <td>Rate of Subsidy (of project cost)</td>
                          </tr>
                          <tr>
                            <td>Categories zawng zawng</td>
                            <td>10%</td>
                            <td>15% (20% in NER and Hill States)</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <figure className="mt-10 border-l border-red-600 pl-9">
                    <blockquote className="font-semibold text-gray-900">
                      <p>
                        Hrechiang duh tan ahnuaia link tarlanah hian a en theih
                        ang
                        <a
                          href="https://www.kviconline.gov.in/pmegpeportal/pmegphome/index.jsp"
                          className="text-red-600 pl-2 text-sm"
                        >
                          https://www.kviconline.gov.in/pmegpeportal/pmegphome/index.jsp
                        </a>
                      </p>
                    </blockquote>
                  </figure>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">Scheme ṭangkaina leh ṭhatna</p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    He scheme hian entrepreneur nih tum te tan kawng mumal tak
                    kawhhmuhna EDP training a buatsaih bakah, an kal zelna tur
                    atan a ṭangkai a an hman theih tur sum leh pai a ṭanpuina a
                    pe bawk a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    He scheme hnuaiah hian Trade thlan tur tam tak a awm a, heng
                    zing a chi khat chauh pawh hmang hian Industry mumal tak a
                    kalpui theih a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    He scheme hnuaiah hian Marketing Support kalpui a ni bawk a,
                    entrepreneur te chuan an sumdawnna huang an zauh theih nan a
                    hmalakpui an ni. Hei hian ramchhung leh ram pawn thlenga in
                    zarpharhna tur hun leh hmun remchang a keng tel a ni.
                  </span>
                </li>
              </ul>

              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>Kum 18 chin chunglam </span>
                </li>
                <li className="flex gap-x-3">
                  <span>Pawl 8 (passed) chin a ni tur a ni </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Thil siam chhuah lam (Manufacturing sector)-ah nuai 10 chin
                    leh sumdawnna leh a kaihhnawih (Business/service) a nuai 5
                    chin project siam te
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Self Help Group (BPL pawhin a dil theih a, scheme danga
                    hamṭhatna dawng an ni tur a ni lo)
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Societies Registration Act, 1860 hnuaia din te tan.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>Production Cooperative Societies te tan.</span>
                </li>
                <li className="flex gap-x-3">
                  <span>Ṭanpuitu pawl (Charitable Trust) </span>
                </li>
              </ul>

              <p className="font-bold mt-8 text-xl">
                Subsidy zat leh hamthatna dawngtu thawh tur zat
              </p>

              <div className="row">
                <div className="col-md-6">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-7 col-xl-12 text-lg-start text-center">
                      <table>
                        <thead>
                          <tr>
                            <th rowspan="2">
                              PMEGP hnuaia hamthatna dawngtu hrnag hrang
                            </th>
                            <th rowspan="2">
                              Hamthatna dawngtu thawh tur zat(project man zat
                              atanga chhutin)
                            </th>
                            <th colspan="3" rowspan="1">
                              Rate of subsidy (project cost atanga hrang hrang
                              thawh tur zat)
                            </th>
                          </tr>
                          <tr>
                            <th>
                              Special Category (incl.
                              SC/ST/OBC/Minorities/Women, Ex-Servicemen,
                              physically handicapped, NER, Hill and Border
                              Areas)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Location(area)</th>
                            <td>10%</td>
                            <td>25%</td>
                          </tr>
                          <tr>
                            <th>General Category</th>
                            <td>15%</td>
                            <td>35%</td>
                          </tr>
                          <tr>
                            <th>
                              Special Category (incl.
                              SC/ST/OBC/Minorities/Women, Ex-Servicemen,
                              physically handicapped, NER, Hill and Border
                              Areas)
                            </th>
                            <td>15%</td>
                            <td>35%</td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="small">
                        <span className="font-italic">
                          Note: (i) Sumdawnna leh a kaihhnawih
                          (Business/service) tan nuai 20 chin project/unit phal
                          a ni. <br />
                          (ii) Project sum hman theih chin chu Bank in Term Loan
                          angin an pe ang
                          <br />
                          (iii) Interest rate (punna) hi Bank rate hmanlai ang a
                          chhut tur a ni <br />
                          (iv) Rulh let hun hi kum 3-7, Bank lamin an siam rem
                          ṭhin ang
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
