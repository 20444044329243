export default function Innovative() {
  const styles = {
    aspectRatio: "801 / 1036",
    width: "50.0625rem",
    background: "linear-gradient(to right bottom, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };
  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:l<div style={styles} /><div style={styles} />eft-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div style={styles} />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              Innovative
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Innovative chu eng nge ni?
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              Innovative scheme chuan MSME kaltlanga miin a duh leh mamawh ang
              inkaihhruaina mumal(guidance); sum leh pai a chhawmdawlna
              (financial support); thiamna ngai bik thil (technical support) leh
              kawng hrang hrang ah ṭanpuina a pe a ni.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-8">
                <blockquote className="text-sm font-semibold leading-8 tracking-tight text-gray-900">
                  <p className="font-bold mt-8 text-xl">
                    Innovative Scheme hlawm hrang(components) pathum te
                  </p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        Incubation Sumdawngten hma an sawn zel theih nana
                        tanpuina pek.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        Design Awmze nei zawka sumdawnna hlawm hrang hrang rem
                        khawm.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        IPR (Intellectual Property Rights) Midang chuh buaina
                        tel lova mahni thil tihchhuah/siamchhuah te hun eng emaw
                        chhunga hman theihna.
                      </span>
                    </li>
                  </ul>

                  <p className="font-bold mt-4">INCUBATION</p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        Sumdawnna huangchhunga eizawnna tling thei tur thil thar
                        hmuhchhuaha, chumi ngaihtuah chhuahna (idea) lo tih
                        hmasawn a, lo dawm kangtu chu a ni. Ṭanpuina sum leh pai
                        hmuh theih chin chu ngaihtuahna (idea) a zir in cheng
                        nuai 15 thleng pek theih a ni.
                      </span>
                    </li>
                  </ul>

                  <figure className="mt-10 border-l border-red-600 pl-9">
                    <blockquote className="font-semibold text-gray-900">
                      <p>
                        Hrechiang duh tan ahnuaia link tarlanah hian a en theih
                        ang
                        <a
                          href="https://innovative.msme.gov.in/"
                          className="text-red-600 pl-2"
                        >
                          https://innovative.msme.gov.in/
                        </a>
                      </p>
                    </blockquote>
                  </figure>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">DESIGN</p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    Mi thiam bik hnen aṭanga kaihhruaina dawn leh a hun tak a
                    design a harsatna thleng thei lak a hautak lo leh inrenchem
                    thei ang ber a chin fel saka, thil awm tawh sa emaw thil
                    thar tan a hmasawnna neih tir zela, a hlutna belhchhah.
                    Ṭanpuina sum leh pai hmuh theih chin chu design project a
                    zirin cheng nuai 40 thleng pekchhuah theih a ni a; Zirlai
                    (student) tan bik he design project ah hian cheng nuai 2.5
                    thleng pekchhuah theih a ni.
                  </span>
                </li>
              </ul>
              <p className="font-bold mt-8 text-xl">IPR</p>
              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    Hei hian a tum ber chu eng thil pawh a siamtu/dintu in hun
                    eng emaw ti chhung neitu nihna a chan theih nan a ṭanpui
                    niin, neitu nihna hian midang/hmun danga lakchhawn(copy)
                    phallohna leh khin theihna bakah thil tam tak a keng tel a
                    ni. In ziahluhna senso te hi pek let leh vek a ni a. A hnuai
                    a tarlan te hi chutianga neitu nihna neih theihna leh chumi
                    a sumfai a ṭanpuina hmuh theih sang ber a ni.
                  </span>
                </li>
              </ul>

              <div className="row mt-5">
                <div className="col-md-6">
                  <div className="row align-items-center">
                    <table id="table1">
                      <thead>
                        <tr>
                          <th>Items</th>
                          <th>Sum hmuh theih chin</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Foreign Patent</td>
                          <td>cheng nuai 5</td>
                        </tr>
                        <tr>
                          <td>Domestic Patent</td>
                          <td>cheng nuai 1</td>
                        </tr>
                        <tr>
                          <td>GI Registration</td>
                          <td>cheng nuai 2</td>
                        </tr>
                        <tr>
                          <td>Design Registration</td>
                          <td>cheng sing 1.5</td>
                        </tr>
                        <tr>
                          <td>Trademark</td>
                          <td>cheng sing 1</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
