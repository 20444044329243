import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" px-20 py-10">
      <h1 className=" font-bold justify-center place-items-center text-2xl">
        Privacy Policy for MEMiz
      </h1>
      <p>Effective Date: 19th December 2024</p>

      <h2>Introduction</h2>
      <p>
        Welcome to MSME Elevate Mizoram. At MEMiz, we prioritize your privacy
        and are committed to providing a secure and private experience for our
        users. This Privacy Policy outlines how MEMiz ensures your privacy when
        you use our app.
      </p>

      <h2>No Data Collection</h2>
      <ul>
        <li>MEMiz is designed with privacy in mind. Our app:</li>
        <li>
          Does not collect personal data: We do not ask for or process
          information such as your name, email, phone number, or any other
          personally identifiable information.
        </li>
        <li>
          Does not collect non-personal data: We do not gather analytics, usage
          statistics, or device-specific information.
        </li>
        <li>
          Does not store data on servers: All actions and data remain confined
          to your device.
        </li>
      </ul>
      <p>
        Your privacy is fully protected, as MEMiz operates without any data
        collection or tracking.
      </p>

      <h2>Device Permissions</h2>
      <p>
        While MEMiz may require certain permissions to enhance your experience
        (such as accessing storage or enabling network connectivity), these
        permissions are solely for the functionality of the app.
      </p>
      <ul>
        <li>
          No data accessed through these permissions is collected, stored, or
          transmitted by us.
        </li>
        <li>
          You can revoke these permissions at any time through your device’s
          settings without affecting the core functionality of MEMiz.
        </li>
      </ul>

      <h2>Third-Party Services</h2>
      <p>
        MEMiz does not integrate with third-party services or platforms that
        collect user data. Your interactions within MEMiz remain private and
        secure.
      </p>

      <h2>How We Protect Your Privacy</h2>
      <p>
        We have taken steps to design MEMiz in a way that prioritizes privacy
        and ensures no external data collection occurs. This means:
      </p>
      <ul>
        <li>
          No data transmission: MEMiz operates entirely offline (if applicable).
        </li>
        <li>
          No third-party integrations: We do not share or transmit your
          activities to external entities or analytics platforms.
        </li>
      </ul>

      <h2>Updates to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy occasionally to reflect changes in our
        app or compliance with legal requirements. Any updates will be posted on
        this page, and the effective date will be revised accordingly. We
        encourage you to review this Privacy Policy periodically to stay
        informed about how we protect your privacy.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions, concerns, or feedback about this Privacy
        Policy or the app, feel free to contact us:
      </p>
      <ul>
        <li>Email: memiztech@gmail.com</li>
        <li>Website: www.memiz.in</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
