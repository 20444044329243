export default function GlobalMart() {
  const styles = {
    aspectRatio: "801 / 1036",
    width: "50.0625rem",
    background: "linear-gradient(to right bottom, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };
  const style2 = {
    aspectRatio: "801 / 1036",
    width: "50.0625rem",
    background: "linear-gradient(to right bottom, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };

  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div style={styles} />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              Global Mart
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Global Mart chu eng nge ni?
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              Global Mart chu sumdawng tenau zawkte’n khawvel huapa an bungrua
              online-a an zawrh ve theih nan a buatsaih a ni.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-8">
                <blockquote className="text-sm font-semibold leading-8 tracking-tight text-gray-900">
                  <p className="font-bold mt-8 text-xl">
                    Member nih dan hrang hrangte:
                  </p>
                  <p className="font-bold mt-4">Basic Membership</p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                                        className="mt-1 h-5 w-5 flex-none text-red-600"
                                        aria-hidden="true"
                                    /> */}
                      <span>Ni 30 chhung hman chhin theih a ni</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                                        className="mt-1 h-5 w-5 flex-none text-red-600"
                                        aria-hidden="true"
                                    /> */}
                      <span> Register nan sum sen a ngai lo</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                                        className="mt-1 h-5 w-5 flex-none text-red-600"
                                        aria-hidden="true"
                                    /> */}
                      <span>
                        Digital Presence siam, thil siam chhuah leh hnathawh
                        theih dan tarlan theih a ni, subcontracting leh
                        distribution lamah offer siam emaw pek chhuah theih a
                        ni, discount hrang hrangte a dawn theih bawk.
                      </span>
                    </li>
                  </ul>

                  <p className="font-bold mt-4">SC/ST Gold Membership</p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span> Kum khat chhung a huam ang</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>Micro leh Small Enterprises te tan chiah a ni</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>
                        He PLAN hi ₹6000 man a ni a, subsidy 80% (₹4,800) paih
                        hnu ah 20% leh GST (@18%) belh khawm pek tur chu kum
                        khat atan ₹1416 a ni ang.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>
                        He PLAN lak hian Global Mart in hamthatna a buatsaih
                        zawng zawngte a huam vek ang.
                      </span>
                    </li>
                  </ul>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">Key memberte tana hamthatna</p>
              <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Minute tlemte chhunga Internet a sumdawnna zauh (Digital
                    Presence) theihna a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span> Sumdawnna 24x7 a kalpui theihna a ni.</span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Thumal behchhan bik (keyword based) neia phal chin awm lova
                    indawrna.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Ram bithliah bik neia khawvel huapa tender hriat theihna a
                    ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Lei leh hralh duhte tan sumdawnna kalpuina awlsam a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Hming hawh sumdawnna, contract inhlanchhawn leh thehdarhna
                    hmun.
                  </span>
                </li>

                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    ICT Digital Services Facilitation ah Special Offer neih.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Healthcare lam kaihhnawiha discount tha tak tak pek.
                  </span>
                </li>

                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>Lei duh te tan quotation buatsaih.</span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Bungraw inhlan hnu a intlawm (Post-Discount) leh lak tama
                    pek tlawm theihna.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span> Links awlsam taka vawn ṭhat.</span>
                </li>
              </ul>

              <figure className="mt-10 border-l border-red-600 pl-9">
                <blockquote className="font-semibold text-gray-900">
                  <p>
                    Hrechiang duh tan ahnuaia link tarlanah hian a en theih ang
                    <a
                      href="https://www.msmemart.com/"
                      className="text-red-600"
                    >
                      https://www.msmemart.com/
                    </a>
                  </p>
                </blockquote>
              </figure>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="relative isolate overflow-hidden bg-white py-24 sm:py-32">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div style={style2} />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              Global Mart
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Global Mart chu eng nge ni?
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              Global Mart chu sumdawng tenau zawkte’n khawvel huapa an bungrua
              online-a an zawrh ve theih nan a buatsaih a ni.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-8">
                <blockquote className="text-sm font-semibold leading-8 tracking-tight text-gray-900">
                  <p className="font-bold mt-8 text-xl">
                    Member nih dan hrang hrangte:
                  </p>
                  <p className="font-bold mt-4">Basic Membership</p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>Ni 30 chhung hman chhin theih a ni</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span> Register nan sum sen a ngai lo</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>
                        Digital Presence siam, thil siam chhuah leh hnathawh
                        theih dan tarlan theih a ni, subcontracting leh
                        distribution lamah offer siam emaw pek chhuah theih a
                        ni, discount hrang hrangte a dawn theih bawk.
                      </span>
                    </li>
                  </ul>

                  <p className="font-bold mt-4">SC/ST Gold Membership</p>
                  <ul
                    role="list"
                    className="mt-1 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span> Kum khat chhung a huam ang</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>Micro leh Small Enterprises te tan chiah a ni</span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>
                        He PLAN hi ₹6000 man a ni a, subsidy 80% (₹4,800) paih
                        hnu ah 20% leh GST (@18%) belh khawm pek tur chu kum
                        khat atan ₹1416 a ni ang.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      {/* <CheckCircleIcon
                        className="mt-1 h-5 w-5 flex-none text-red-600"
                        aria-hidden="true"
                      /> */}
                      <span>
                        He PLAN lak hian Global Mart in hamthatna a buatsaih
                        zawng zawngte a huam vek ang.
                      </span>
                    </li>
                  </ul>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <p className="font-bold text-xl">Key memberte tana hamthatna</p>
              <ul role="list" className="mt-8 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Minute tlemte chhunga Internet a sumdawnna zauh (Digital
                    Presence) theihna a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span> Sumdawnna 24x7 a kalpui theihna a ni.</span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Thumal behchhan bik (keyword based) neia phal chin awm lova
                    indawrna.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Ram bithliah bik neia khawvel huapa tender hriat theihna a
                    ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Lei leh hralh duhte tan sumdawnna kalpuina awlsam a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Hming hawh sumdawnna, contract inhlanchhawn leh thehdarhna
                    hmun.
                  </span>
                </li>

                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    ICT Digital Services Facilitation ah Special Offer neih.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Healthcare lam kaihhnawiha discount tha tak tak pek.
                  </span>
                </li>

                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>Lei duh te tan quotation buatsaih.</span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span>
                    Bungraw inhlan hnu a intlawm (Post-Discount) leh lak tama
                    pek tlawm theihna.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  {/* <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-red-600"
                    aria-hidden="true"
                  /> */}
                  <span> Links awlsam taka vawn ṭhat.</span>
                </li>
              </ul>

              <figure className="mt-10 border-l border-red-600 pl-9">
                <blockquote className="font-semibold text-gray-900">
                  <p>
                    Hrechiang duh tan ahnuaia link tarlanah hian a en theih ang
                    <a
                      href="https://www.msmemart.com/"
                      className="text-red-600 pl-2"
                    >
                      https://www.msmemart.com/
                    </a>
                  </p>
                </blockquote>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
