export default function Test() {
  if (window.DeviceOrientationEvent) {
    DeviceOrientationEvent.requestPermission().then(()=>{

      window.addEventListener(
        "deviceorientation",
        (event) => {
          const rotateDegrees = event.alpha; // alpha: rotation around z-axis
          const leftToRight = event.gamma; // gamma: left to right
          const frontToBack = event.beta; // beta: front back motion

          window.alert(rotateDegrees)
        },
        true,
      );
    })
  }
  else{
    window.alert("Device Orientation API not supported")
  }
  return (
    <div className="flex flex-col">

    </div>
  );
}
