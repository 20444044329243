export default function Zed() {
  const styles = {
    aspectRatio: "801/1036",
    width: "50.0625rem",
    background: "linear-gradient(to top right, #ff80b5, #9089fc)",
    opacity: 0.3,
    clipPath:
      "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
  };
  return (
    <div className="">
      <div className="relative isolate overflow-hidden bg-white py-10 sm:py-10">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={styles}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-red-600">
              ZED
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              ZED chu eng nge ni?
            </h1>
            <ul role="list" className="mt-5 max-w-xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <span>
                  Zero Defect & Zero Effect (ZED) Scheme hi sumdawngte (MSME)
                  tana hmasawnna tiṭhuanawp si lova an chhehvel (environment)
                  tichhe lo tur zawnga intiamkamna leh bana kaih ṭanpuina dawn
                  theihna a ni.
                </span>
              </li>
            </ul>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
            <div className="relative lg:order-last lg:col-span-5">
              <svg
                className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                    width="200"
                    height="200"
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                  </pattern>
                </defs>
                <rect
                  width="100%"
                  height="100%"
                  stroke-width="0"
                  fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
                />
              </svg>
              <figure className="border-l border-red-600 pl-8">
                <blockquote className="text-sm font-semibold leading-8 tracking-tight text-gray-900">
                  <p className="font-bold mt-8 text-xl">Dil thei te</p>
                  <span>
                    ZED Certification atan hian MSME zawng zawngte’n ZED Pledge
                    an la hmasa vek tur a ni a. ZED Certification phut angin an
                    thil siamchhuah chu ṭha leh teh tlo (quality &
                    sustainability), hmasawn zel (continuous improvement) thei
                    turin intiamkamna neih hmasak a ni ṭhin.
                  </span>

                  <ul
                    role="list"
                    className="mt-4 max-w-xl space-y-4 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        Ban kaih ṭanpuina hi sawrkar in subsidy (man tlawm
                        zawkin) a pe thei a, hetiangin:
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span> Micro Enterprises: 80% </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span> Small Enterprises: 60% </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span> Medium Enterprises: 50% </span>
                    </li>
                    <span>10% additional dil theih a ni bawk.</span>
                  </ul>

                  <figure className="mt-10 border-l border-red-600 pl-9">
                    <blockquote className="font-semibold text-gray-900">
                      <p>
                        Hrechiang duh tan ahnuaia link tarlanah hian a en theih
                        ang
                        <a
                          href="https://zed.msme.gov.in/"
                          className="text-red-600 pl-2"
                        >
                          https://zed.msme.gov.in/
                        </a>
                      </p>
                    </blockquote>
                  </figure>
                </blockquote>
              </figure>
            </div>
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
              <span className="font-extrabold text-xl">
                Ban kaih ṭanpuina (Handholding through Consultancy)
              </span>

              <ul role="list" className="mt-4 max-w-xl space-y-4 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    Mamawhna a zirin cheng nuai 5 chin hi handholding support
                    pek theih a ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Technology Upgradation atan cheng nuai 3 chin hman theih a
                    ni.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    Project Monitoring and Advisory Committee (PMAC) pawmpuina
                    in ban kaih ṭanpuina (handholding) cheng nuai 2 Zero Effect
                    Solutions Verification atan MSME-Development Institute
                    aṭangin dawn theih a ni bawk.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    MSME Bronze Certification nei chin tan a dil theih ang.
                  </span>
                </li>
              </ul>

              <div className="col-md-6">
                <div className="row align-items-center mt-7">
                  <h4 className="font-extrabold text-xl mb-3">
                    Sum kaihhnawih
                  </h4>
                  <table>
                    <tr>
                      <th colspan="2">Certification Level</th>

                      <th>A man zat</th>
                    </tr>
                    <tr>
                      <td>Certification Level 1</td>
                      <td>BRONZE</td>
                      <td>10,000</td>
                    </tr>
                    <tr>
                      <td>Certification Level 2</td>
                      <td>SILVER</td>
                      <td>40,000</td>
                    </tr>
                    <tr>
                      <td>Certification Level 3</td>
                      <td>GOLD</td>
                      <td>90,000</td>
                    </tr>
                  </table>
                  <span className="text-xs font-bold">
                    A hnuaia mi ang hian subsidy buatsaih a ni:
                    <br />
                    Micro Enterprises : Certification man zat aṭanga 80% <br />
                    Small Enterprises : Certification man zat aṭanga 60% <br />
                    Medium Enterprises : Certification man zat aṭanga 50% <br />
                    Note: *MSME hmeichhia / SC / ST sumdawng emaw NER /
                    Himalayan / LWE / Island territories / aspirational
                    districts a awm te tan 10% subsidy a awm belh thei ang.{" "}
                    <br />
                    *Ministry program SFURTI emaw MSE-CDP a tel te chuan 5%
                    subsidy an lak belh thei ang. <br />
                    *MSME-te hian ZED Pledge an lak veleh ₹10,000 an dawng thei
                    ang a. Kum khat chhungin lak ngei ngei tur a ni ang. He
                    Joining Reward hi ṭul leh ṭha an tih ang zelin hman theih a
                    ni ang.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
