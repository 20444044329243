import Authenticated from "../Layout/Authenticated";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import { InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format, formatDate } from "date-fns";
import {
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { Edit } from "@mui/icons-material";

const rows = [
  {
    id: 1,
  },
];

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  // {
  //   id: "address",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Address",
  //   sortable: false,
  // },

  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
  // {
  //   id: "protein",
  //   numeric: true,
  //   sortable: false,
  //   disablePadding: false,
  //   label: "",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const user = useSelector((state) => state.user);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function AllUdyamPage({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState("");
  const [tempData, setTempData] = useState({});
  const [isBusy, setIsbusy] = useState(true);

  const [entities, setEntities] = useState({
    data: [],
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);

  const createNewQueryType = () => {
    setOpen(true);
    setTempData({
      name: "",
    });
  };
  const submit = (e) => {
    e.preventDefault();
    if (tempData.id) {
      api
        .post(`/api/queryType/${tempData.id}`, {
          ...tempData,
          ...{ _method: "patch" },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          getData(page);
          handleClose();
          setIsbusy(false)
        });
    } else {
      api
        .post("/api/queryType", tempData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          getData(page);
          handleClose();
          setIsbusy(false)
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, data) => {
    event.stopPropagation();
    setTempData(data);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Saiha" },
    { id: 8, name: "Lawngtlai" },
    { id: 9, name: "" },
    // More users...
  ];

  const navigate = useNavigate();

  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/queryType`);
    page && url.searchParams.append("page", page);
    startDate && url.searchParams.append("startDate", startDate);

    axios
      .get(url)
      .then((response) => {
        setEntities(response.data);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <Authenticated>
      <div className="px-4 sm:px-6 lg:px-8 p-3 overflow-hidden relative">
        <div className="fixed right-5 bottom-5"></div>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className=" sm:flex sm:flex-row items-center justify-center w-full">
              <div className="text-lg font-semibold">
                {tempData?.id ? (
                  <div>
                    <div className="text-lg font-semibold">Query Type ID :</div>
                    <div className="text-lg font-light ml-3">{tempData.id}</div>
                  </div>
                ) : (
                  <div className="">Create New</div>
                )}
              </div>
            </div>
          </DialogTitle>

          <div className="p-9">
            <form onSubmit={submit}>
              <TextField
                label="Name"
                className="w-full"
                size="small"
                required
                value={tempData.name}
                onChange={(e) =>
                  setTempData({...tempData, ...{ name: e.target.value }})
                }
              />
              <div className="col-span-2 grid grid-cols-2  gap-x-2 mx-3 mt-5">
                <Button
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  tabIndex={0}
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Dialog>

        <div className="">
          <div className="">
            <Button variant="contained" onClick={createNewQueryType}>
              New Type
            </Button>
          </div>
          <div className="md:mt-10 mt-10 grid grid-cols-1 gap-4 md:grid-cols-6">
            <TextField
              label="Name"
              className="w-full"
              size="small"
              value={tempData.udyam_id}
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className=" col-span-2 grid grid-cols-2  gap-x-2 mx-3">
              <Button variant="contained" size="small">
                Submit
              </Button>
              <Button variant="contained" color="error" size="small">
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isBusy ? (
              <div className="flex justify-center">
                <InfinitySpin
                  visible={true}
                  width="200"
                  color="#4fa94d"
                  ariaLabel="infinity-spin-loading"
                />
              </div>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="max-w-full overflow-hidden">
                  <Paper>
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>
                          {entities?.data.map((row, index) => {
                            const isItemSelected = selected.includes(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                >
                                  <Button>
                                    <Edit />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="relative py-3">
                    <div className="mt-2 w-full md:absolute md:left-1/2 md:-translate-x-1/2 flex justify-center z-30">
                        <Pagination
                          count={Math.ceil(entities.total / entities.per_page)}
                          page={entities.current_page}
                          onChange={(e, number) => getData(number)}
                        />
                      </div>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={entities?.total}
                        rowsPerPage={entities?.per_page}
                        page={entities?.current_page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
