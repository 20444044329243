import Authenticated from "./Layout/Authenticated";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import UdyamPage from "./screens/InProcess";
import AddUdyamPage from "./screens/AddUdyamPage";
import Dashboard from "./screens/Dashboard";
import UdyamInfo from "./screens/UdyamInfo";
import IndexPage from "./screens/IndexPage";
import AssignmentPage from "./screens/AssignmentPage";
import IndexInfo from "./screens/IndexInfo";
import VerifyPage from "./screens/VerifyPage";
import Login from "./screens/LoginPage";
import AllUdyamPage from "./screens/AllUdyamPage";
import Users from "./screens/UserPage";
import EditProfile from "./screens/ProfileEdit";
import Test from "./screens/Test";
import EditModel from "./screens/EditModelPage";
import ApprovedPage from "./screens/ApprovedPage";
import MyUdyamPage from "./screens/MyUdyamPage";
import { Verify } from "./screens/Verify";
import Landing from "./screens/Landing";
import QueryTypePage from "./screens/QueryTypePage";
import Query from "./screens/QueryPage";
import DeleteApprovalPage from "./screens/DeleteApprovalPage";
import UserPage from "./screens/UserPage";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../src/redux/userReducer";
import { useEffect, useState } from "react";
import { api } from "./axios";
import PrivacyPolicy from "./screens/PrivacyPage";
import Cgstmse from "./schemes/CgstmsePage";
import Cicss from "./schemes/CicssPage";
import GlobalMart from "./schemes/GlobalMartPage";
import Innovative from "./schemes/InnovativePage";
import Cdp from "./schemes/MsmeCpdPage";
import Pms from "./schemes/PmsPage";
import Pmegp from "./schemes/PmegpPage";
import Zed from "./schemes/ZedPage";
import Sfurti from "./schemes/SfurtiPage";
import TestPages from "./schemes/Test";

function App({ Component, pageProps }) {
  const dispatch = useDispatch();

  const getUser = async () => {
    api
      .get("/api/user?getme=1")
      .then((res) => {
        dispatch(
          setUser({
            name: res.data?.name,
            email: res.data?.email,
            phone: res.data.phone,
            roles: res.data.roles,
            district: res.data.district,
          })
        );
      })
      .catch((err) => {
        if (err.status === 401) {
          console.log(err);
        } else {
          console.log(err);
        }
      });
  };
  useEffect(() => {
    getUser();
  }, []);

  const user = useSelector((state) => state.user);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/editProfile" element={<EditProfile />} />
        <Route exact path="/verify" element={<Verify />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/test" element={<Test />} />
        <Route exact path="/udyam" element={<UdyamPage />} />
        <Route exact path="/alludyam" element={<AllUdyamPage />} />
        <Route exact path="/approved" element={<IndexPage />} />
        <Route exact path="/addudyam" element={<AddUdyamPage />} />
        <Route exact path="/assignment" element={<AssignmentPage />} />
        <Route exact path="/info/:id" element={<UdyamInfo />} />
        <Route exact path="/index/:id" element={<IndexInfo />} />
        <Route exact path="/verify/:id" element={<VerifyPage />} />
        <Route exact path="/users" element={<Users />} />
        <Route exact path="/edit" element={<EditProfile />} />
        <Route exact path="/approvedPage" element={<ApprovedPage />} />
        <Route exact path="/queryType" element={<QueryTypePage />} />
        <Route exact path="/query" element={<Query />} />
        <Route exact path="/deleteApproval" element={<DeleteApprovalPage />} />
        <Route exact path="/user" element={<UserPage />} />
        <Route exact path="/editModel" element={<EditModel />} />
        <Route exact path="/myUdyam" element={<MyUdyamPage />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/cgstmse" element={<Cgstmse />} />
        <Route exact path="/cicss" element={<Cicss />} />
        <Route exact path="/global" element={<GlobalMart />} />
        <Route exact path="/innovative" element={<Innovative />} />
        <Route exact path="/cdp" element={<Cdp />} />
        <Route exact path="/pms" element={<Pms />} />
        <Route exact path="/pmegp" element={<Pmegp />} />
        <Route exact path="/zed" element={<Zed />} />
        <Route exact path="/sfurti" element={<Sfurti />} />
      </Routes>
    </Router>
  );
}

export default App;
